<template>
  <ciam-card-content-line type="full-width">
    <template v-slot:content>
      <ciam-card>
        <ciam-card-header>
          <template v-slot:header><span v-once v-text="$t('deployment.exports.export.header')"></span></template>
          <template v-slot:subtitle><span v-once v-text="$t('deployment.exports.export.subtitle')"></span></template>
          <template v-slot:actions>
            <ciam-button
              :disabled="!canTriggerExport()"
              @click="startExport()"
              class="secondary"
              data-e2e="deployment-export"
            >
              <span v-once v-text="$t('deployment.exports.export.button')"></span>
              <template v-slot:left>
                <ciam-icon name="fa-plus"></ciam-icon>
              </template>
            </ciam-button>
          </template>
        </ciam-card-header>
        <div>
          <Promised :promise="loading">
            <template v-slot:pending>
              <ciam-loader class="py-4"></ciam-loader>
            </template>

            <template v-slot:rejected="problem">
              <ciam-alert
                v-if="(problem.type = 'not-available-on-current-plan')"
                :title="problem.title"
                :description="getUpsellAlertDescription()"
                :type="AlertStatus.WARNING_HREF"
                :href-text="$t('upsell.email.contact')"
                :href="getUpsellAlertHref()"
              />
            </template>

            <template>
              <ciam-card-content-line type="full-width">
                <template v-slot:content>
                  <b-table style="clear: both" :striped="true" :hoverable="true" :data="exports">
                    <b-table-column field="name" :label="$t('deployment.exports.export.name')" v-slot="props">
                      <ciam-text>{{ props.row.name }}</ciam-text>
                    </b-table-column>

                    <b-table-column field="startedAt" :label="$t('deployment.exports.export.startedAt')" v-slot="props">
                      <ciam-text :title="props.row.startedAt | localeDate"
                        >{{ fromNow(props.row.startedAt) }}
                      </ciam-text>
                    </b-table-column>

                    <b-table-column field="status" :label="$t('deployment.exports.export.status')" v-slot="props">
                      <ciam-text
                        >{{ $t('deployment.exports.export.statuses.' + props.row.status) }}
                        {{ elapsed(props.row.startedAt, props.row.endedAt) }}
                      </ciam-text>
                    </b-table-column>

                    <b-table-column v-slot="props">
                      <template>
                        <div style="display: flex; justify-content: flex-end">
                          <ciam-link :href="props.row.url" v-if="isReady(props.row.status)">
                            <ciam-icon name="fa-download"></ciam-icon>
                          </ciam-link>
                        </div>
                      </template>
                    </b-table-column>

                    <template #empty>
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>{{ $t('deployment.exports.export.emptyList') }}</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </template>
              </ciam-card-content-line>
            </template>
          </Promised>
        </div>
      </ciam-card>
    </template>
  </ciam-card-content-line>
</template>

<script>
import ExportService from './ExportService';
import CiamCardContentLine from '@/components/CiamCardContentLine';
import CiamButton from '@/components/CiamButton';
import CiamIcon from '@/components/CiamIcon';
import CiamCardHeader from '@/components/CiamCardHeader';
import CiamCard from '@/components/CiamCard';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import CiamLink from '@/components/CiamLink';
import moment from 'moment';
import { Notification } from 'vue-notifyjs';
import i18n from '@/i18n';
import { makeQueryablePromise } from '@/util/promise';
import UpsellFeatureService from '@/pages/UpsellFeatureService';

export default {
  props: {},
  name: 'DeploymentExports',
  components: {
    CiamAlert,
    CiamLink,
    CiamButton,
    CiamCardContentLine,
    CiamCard,
    CiamCardHeader,
    CiamIcon,
  },
  data() {
    return {
      AlertStatus: AlertStatus,
      loading: makeQueryablePromise(Promise.reject()),
      isFulfilled: false,
      exports: [],
      hasError: false,
    };
  },
  mounted() {
    this.loading = Promise.all([ExportService.getForDeployment(this.$route.params.id)]).then(([exports]) => {
      this.exports = exports;
      this.isFulfilled = true;
    });
  },
  methods: {
    getUpsellAlertHref() {
      return UpsellFeatureService.getEmailHref(i18n.t(`deployment.exports.featureName`));
    },

    getUpsellAlertDescription() {
      return UpsellFeatureService.getEmailDescription(i18n.t(`deployment.exports.featureName`));
    },
    canTriggerExport() {
      return this.isFulfilled === true && !this.exports.some((e) => e.status === 'IN_PROGRESS');
    },
    fromNow(startedAt) {
      return moment(startedAt).fromNow();
    },
    elapsed(startedAt, endedAt) {
      startedAt = moment(startedAt);
      if (endedAt == null) {
        return '';
      } else {
        endedAt = moment(endedAt);
      }
      return `(took ${endedAt.to(startedAt, true)})`;
    },
    isReady(status) {
      return 'READY' === status;
    },
    startExport() {
      const deploymentId = this.$route.params.id;
      this.isFulfilled = false;
      ExportService.triggerExport(deploymentId)
        .then(() => {
          Notification.notify({
            message: i18n.t('deployment.exports.export.notification.success'),
            type: 'info',
          });
        })
        .catch(() => {
          Notification.notify({
            message: i18n.t('deployment.exports.export.notification.failure'),
            type: 'danger',
          });
        })
        .finally(() => {
          this.loading = ExportService.getForDeployment(deploymentId).then((exports) => {
            this.exports = exports;
            this.isFulfilled = true;
          });
        });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
ul.ciam-logs {
  height: 50vh;
  resize: vertical;

  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

ul.ciam-logs li {
  line-height: normal;
  font-family: SourceCodePro, monospace;
  font-size: 0.9em;
}

ul.ciam-logs li:hover,
ul.ciam-logs li:hover span:first-child {
  background: #fafad2;
  color: #0a0a0a;
}

/* timestamp */
ul.ciam-logs li span:first-child {
  color: #fff;
  padding: 0 5px;
  margin: 1px 0;
  background: #bcc2d1;
  border-radius: 2px;
  display: inline-block;
}
</style>
