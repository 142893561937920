import { problemAwareHttp } from '@/http';

export default class ExportService {
  /**
   * @param {String} deploymentId UUID
   * @returns {Promise<any>}
   */
  static async triggerExport(deploymentId) {
    return problemAwareHttp.post(`/deployments/${deploymentId}/exports/`, {});
  }

  /**
   *
   * @param deploymentId
   * @returns {undefined}
   */
  static getForDeployment(deploymentId) {
    return problemAwareHttp.get(`/deployments/${deploymentId}/exports`).then((result) => result.data);
  }
}
