import i18n from '@/i18n';

// This Upsell feature service allows you to generate an upsell email (ex: body_email, description, href, etc.) for a specific feature (ex: logs, trigger on call, etc.)
export default class UpsellFeatureService {
  /**
   * Get upsell feature email description
   * @property {string} feature_name // The name of the feature to upsell (eg. logs)
   * @property {string} description // The description of the feature to upsell
   */
  static getEmailDescription(feature_name, description) {
    if ((description == null) | (description == '') | (description == undefined)) {
      description = i18n.t('upsell.description').replace('{featureName}', `${feature_name}`);
    }
    return description;
  }

  /**
   * Get upsell feature email href
   * @property {string} feature_name // The name of the feature to upsell (eg. logs)
   * @property {string} contact_email // The To contact in the email (default: upsell.email.contact)
   * @property {string} subject_email // The Subject in the email (default: upsell.email.subject)
   * @property {string} body_email // The body in the email (default: upsell.email.body)
   */
  static getEmailHref(feature_name, contact_email, subject_email, body_email) {
    if ((subject_email == null) | (subject_email == '') | (subject_email == undefined)) {
      subject_email = i18n.t('upsell.email.subject').replace('{featureName}', `${feature_name}`);
    }
    if ((contact_email == null) | (contact_email == '') | (contact_email == undefined)) {
      contact_email = i18n.t('upsell.email.contact');
    }

    if ((body_email == null) | (body_email == '') | (body_email == undefined)) {
      body_email = i18n.t('upsell.email.body').replace('{featureName}', `${feature_name}`);
    }
    const href = `mailto:${contact_email}?subject=${encodeURIComponent(`${subject_email}`)}&body=${encodeURIComponent(
      body_email
    )}`;

    return href;
  }
}
